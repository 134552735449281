import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { APP_INITIALIZER } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Ng5BreadcrumbModule, BreadcrumbService } from 'ng5-breadcrumb';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { ToastrModule } from 'ngx-toastr';

import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireModule } from 'angularfire2'


import {
	MatSlideToggleModule, MatButtonModule, MatBadgeModule, MatCardModule, MatMenuModule, MatToolbarModule, MatIconModule,
	MatInputModule, MatDatepickerModule, MatNativeDateModule, MatProgressSpinnerModule, MatTableModule, MatExpansionModule, MatSelectModule,
	MatSnackBarModule, MatTooltipModule, MatChipsModule, MatListModule, MatSidenavModule, MatTabsModule, MatProgressBarModule,
	MatCheckboxModule, MatSliderModule, MatRadioModule, MatDialogModule, MatGridListModule
} from '@angular/material';

import { RoutingModule } from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { AuthService } from './service/auth-service/auth.service';
import { PageTitleService } from './core/page-title/page-title.service';

import { EcclesiaAppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { MenuToggleModule } from './core/menu/menu-toggle.module';
import { MenuItems } from './core/menu/menu-items/menu-items';
import { AuthGuard } from './core/guards/auth.guard';
import { WidgetComponentModule } from './widget-component/widget-component.module';
import { SideBarComponent } from './shared/side-bar/side-bar.component';

import { LowerCasePipe, TitleCasePipe, CurrencyPipe } from '@angular/common';
import { PipeModule } from './shared/pipes/pipe.module';
// import { DateFilterPipe } from './shared/pipes/datefilter.pipe';
import { GTranslateModule } from 'ngx-g-translate';
import { ImageCropperModule } from 'ngx-image-cropper';
// import { MatTableExporterDirective, MatTableExporterModule } from 'mat-table-exporter';
import { MatTableExporterModule } from 'mat-table-exporter';

import { AppConfig } from '../app/app-config';
import { CustomInterceptor } from './service/auth-service/oauth.service';
import { NGXToastrService } from './service/auth-service/toastr.service';
import { D3ChartService } from './core/nvD3/nvD3.service';
//  import { EcclesiaRegistrationFormModule } from './ecclesia-registration-form/ecclesia-registration-form.module';


export function initializeApp(appConfig: AppConfig) {
	return () => appConfig.load();
}

export const firebaseConfig = {
	apiKey: 'AIzaSyBO0CLP4fOA_kanqw1HQ2sDjEkyuK9lQ3o',
	authDomain: 'gene-ccf5f.firebaseapp.comm',
	databaseURL: 'https://gene-ccf5f.firebaseio.com',
	projectId: 'gene-ccf5fc',
	storageBucket: 'gene-ccf5f.appspot.com',
	messagingSenderId: '907778578362'
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true
};

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		DeviceDetectorModule.forRoot(),
		RoutingModule,
		FlexLayoutModule,
		NgbModalModule.forRoot(),
		Ng5BreadcrumbModule.forRoot(),
		TourMatMenuModule.forRoot(),
		PerfectScrollbarModule,
		MenuToggleModule,
		PipeModule,
		HttpClientModule,
		HttpModule,
		MatSlideToggleModule,
		GTranslateModule,

		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		AngularFireModule.initializeApp(firebaseConfig),
		AngularFireAuthModule,
		MatButtonModule,
		MatCardModule,
		MatMenuModule,
		MatToolbarModule,
		MatIconModule,
		MatBadgeModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatExpansionModule,
		MatSelectModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatChipsModule,
		MatListModule,
		MatSidenavModule,
		MatTabsModule,
		MatProgressBarModule,
		MatCheckboxModule,
		MatSliderModule,
		MatRadioModule,
		MatDialogModule,
		MatGridListModule,
		MatTableExporterModule,

		ToastrModule.forRoot(),
		// ToastrModule.forRoot({ timeOut: 6000 }),
		ToastrModule.forRoot({
			iconClasses: {
				error: 'toast-error',
				info: 'toast-info',
				success: 'toast-success',
				warning: 'toast-warning'
			},
			autoDismiss: true,
			maxOpened: 1,
			timeOut: 6000,

			positionClass: 'toast-top-right',
			preventDuplicates: true,
		}),
		WidgetComponentModule,
		LoadingBarRouterModule,
		LoadingBarRouterModule,
		ImageCropperModule,
		//  EcclesiaRegistrationFormModule
	],
	declarations: [
		EcclesiaAppComponent,
		MainComponent,
		SideBarComponent,
		
	],
	bootstrap: [EcclesiaAppComponent],
	providers: [
		MenuItems,
		D3ChartService,
		BreadcrumbService,
		PageTitleService,
		AuthService,
		CustomInterceptor,
		LowerCasePipe,
		CurrencyPipe,
		TitleCasePipe,
		NGXToastrService,
		AppConfig,
		
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		{ provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true },
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [AppConfig],
			multi: true
		},
		AuthGuard,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GeneAppModule { }
